import React from 'react';
import { InView } from 'react-intersection-observer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import SEO from '../components/SEO';

const DesignStyles = styled.div`
  scroll-behavior: auto;
  padding: 12rem 10rem 8rem;

  @media (max-width: 900px) {
    padding: 8rem 6rem 6rem;
  }
  @media (max-width: 600px) {
    padding: 8rem 6rem 6rem;
  }
  @media (max-width: 500px) {
    padding: 6rem 3rem 4rem;
  }

  .overview {
    display: grid;
    grid-template-columns: 1fr 50%;
    gap: 3rem;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }
  .overview__right {
    margin-top: 6rem;
    animation: animation-3rd 1s linear;

    @media (max-width: 900px) {
      margin-top: 1rem;
    }
  }
  .overview__title {
    margin-bottom: 4rem;
    animation: animation-3rd 0.6s linear;
  }
  .overview__image-container {
    max-width: 42rem;
    max-height: 35rem;
    overflow: hidden;
    box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.1);
    animation: animation-3rd 0.8s linear;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
  .overview__image {
  }
  .overview__desc {
    line-height: 1.6em;
  }
  .overview__solution {
    margin-top: 4rem;
    font-size: 2rem;
    line-height: 1.5em;
    color: var(--grey--dark);

    @media (max-width: 600px) {
      margin-top: 1rem;
    }
  }

  .mockups {
    padding: 16rem 10rem 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8rem;

    @media (max-width: 900px) {
      padding: 8rem 4rem 0;
    }
    @media (max-width: 600px) {
      padding: 10rem 0 0;
    }
  }
  .mockup {
    position: relative;
    height: 55rem;
    box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.1);

    @media (max-width: 600px) {
      height: 40rem;
    }
  }
  .mockup.is-visible {
    animation: fadeInView 1s linear;
  }
  .mockup__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .mockup__caption {
    position: absolute;
    width: 20rem;
    text-align: center;
    top: 70%;
    --translateX: 0;
    --translateY: 0;
    transform: translate(var(--translateX), var(--translateY));
    line-height: 1.2em;

    @media (max-width: 900px) {
      top: 15%;
      background: rgba(255, 255, 255, 0.8);
    }
    @media (max-width: 600px) {
      top: 0;
      --translateY: -50%;
    }
  }
  .mockup:nth-child(odd) .mockup__caption {
    left: 0;
    --translateX: -50%;

    @media (max-width: 600px) {
      --translateX: 0;
    }
  }
  .mockup:nth-child(even) .mockup__caption {
    right: 0;
    --translateX: 50%;

    @media (max-width: 600px) {
      --translateX: 0;
    }
  }
  @keyframes animation-3rd {
    0%,
    60% {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

export default function DesignPage({
  data: {
    design: { name, description, solution, image, mockups },
  },
}) {
  return (
    <>
      <SEO title={name} description={description} />
      <DesignStyles>
        <div className="overview">
          <div className="overview__left">
            <h2 className="overview__title h--bold">{name}</h2>
            <div className="overview__image-container">
              <Img className="overview__image" fluid={image.asset.fluid} />
            </div>
          </div>
          <div className="overview__right">
            <h4 className="overview__desc h--medium">{description}</h4>
            <p className="overview__solution">{solution}</p>
          </div>
        </div>
        <ul className="mockups">
          {mockups.map(({ id, caption, image, name }) => (
            <InView triggerOnce key={id}>
              {({ inView, ref }) => (
                <li
                  ref={ref}
                  className={`mockup ${inView ? 'is-visible' : ''}`}
                >
                  <Img
                    fluid={image.asset.fluid}
                    className="mockup__image"
                    alt={name}
                  />
                  <h3 className="mockup__caption h--bold">{caption}</h3>
                </li>
              )}
            </InView>
          ))}
        </ul>
      </DesignStyles>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    design: sanityDesign(slug: { current: { eq: $slug } }) {
      id
      name
      slug {
        current
      }
      time
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      description
      solution
      mockups {
        id
        slug {
          current
        }
        name
        caption
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
